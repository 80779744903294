import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import swConfig from './swConfig';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <Suspense fallback={null}>
      <App />
    </Suspense>
  </BrowserRouter>
);

serviceWorkerRegistration.register(swConfig);

// After registering, subscribe the user to push notifications
/* navigator.serviceWorker.ready.then(async (registration) => {
  const subscription = await subscribeUserToPush(registration);
  console.log('Push subscription:', subscription);

  // Send subscription to backend
  await fetch('/api/subscribe', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(subscription),
  });
}); */
/*   "privateKey": "QOsaKgV6oW80S3C04N_iLzs6Jw3ry53AS_tdZJeI_UU" */
/*  "subject": "mailto:letsgo@etsnord.com", */
// Function to subscribe to push notifications
/* async function subscribeUserToPush(serviceWorkerRegistration: ServiceWorkerRegistration) {
  const publicKey = 'BE79L1Q-EdxJP91JJ1V8Tq5ZqxUVcvbiN53AdT48CAYF309AXJivT4WH5C4mpeYx3olvoM2aNufj_3vlMrOqOgk'; // Replace with your VAPID public key

  return await serviceWorkerRegistration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(publicKey),
  });
}

// Helper to convert VAPID key
function urlBase64ToUint8Array(base64String: string): Uint8Array {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
} */

reportWebVitals();